import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Account } from "@radixdlt/radix-dapp-toolkit"
import { useState, Fragment, useEffect } from "react";
import { classNames, getIconUrl, shortenAddress } from "~/lib/util"
import { useRdtState } from "~/rdt/hooks/useRdtState";

const STORAGE_KEY = 'radixplaza:selected_account';

type Props = {
	mode?: null | 'light' | 'dark',
	onAccount: (value: Account) => void
}

export default function AccountSelector({ mode, onAccount }: Props) {
	const walletData = useRdtState();

	const [account, setAccount] = useState<Account | null>(null);
	const [accounts, setAccounts] = useState<Account[]>([]);
	
   useEffect(() => {
      if (walletData?.accounts.length) {
         setAccounts(walletData?.accounts);

         const selectedAccount = window.localStorage.getItem(STORAGE_KEY);

         if (selectedAccount) {
            const account = walletData?.accounts.filter((a) => a.address === selectedAccount);

            if (account && account?.length > 0) {
               changeAccount(account[0]);
               return;
            }
         }

         changeAccount(walletData?.accounts[0]);
      }
   }, [walletData]);

   function changeAccount(account: Account) {
      setAccount(account);
      window.localStorage.setItem(STORAGE_KEY, account.address);

      if (onAccount) onAccount(account);
   }

	return account && accounts.length > 1 ? (
		<section className={classNames("_backdrop-blur bg-black/30 px-4 py-2 rounded-t-lg")}>
			{/* <label htmlFor="account" className={classNames('text-sm font-medium text-slate-600 dark:text-slate-200')}>
            Account
         </label> */}
			<Listbox name="account" value={account} onChange={changeAccount}>
				{({ open }) => (
					<>
						<div className="relative">
							<Listbox.Button className={classNames(mode == "dark" ? "" : "", "focus:outline-3 relative w-full rounded-md py-1 pr-10 text-left text-white focus:ring-1")}>
								<div className="flex items-center justify-between">
									<p className="font-semibold">{account.label || "Unknown"}</p>
									<p className="text-xs">{shortenAddress(account.address)}</p>
								</div>
								<span className="pointer-events-none absolute inset-y-0 right-0 ml-2 flex items-center">
									<ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
								</span>
							</Listbox.Button>

							<Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
								<Listbox.Options className="rounded-bg-lg absolute z-50 mt-2 max-h-56 w-full overflow-auto bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
									{accounts.map((account) => (
										<Listbox.Option key={account.address} value={account} className="relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-neutral-200">
											{({ selected }) => (
												<>
													<div className="flex items-center">
														<div className={classNames(selected ? "font-semibold" : "font-normal", "ml-3 block truncate")}>
															<p className="title font-semibold">{account.label || "Unknown"}</p>
															<p className="rtl truncate text-xs">{account.address}</p>
														</div>
													</div>
												</>
											)}
										</Listbox.Option>
									))}
								</Listbox.Options>
							</Transition>
						</div>
					</>
				)}
			</Listbox>
		</section>
	) : null;
}